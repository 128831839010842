import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

import Layout from "../components/layout"
import "../components/assets/css/style.css"
import "../components/assets/css/hover-effects.css"
import "../components/assets/css/bootstrap.min.css"

import logo1 from "../components/assets/images/logo_layer_back.png"
import logo2 from "../components/assets/images/logo_layer_second.png"
import logo3 from "../components/assets/images/logo_layer_top.png"

class PageTemplate extends Component {
    render() {
        // STEP #5: Use title and content in Gatsby.
        return (
            <Layout className="maintenance">
                <Helmet>
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js" />
                    <script src={withPrefix('script.js')} type="text/javascript" />
                </Helmet>
                <div className="overlay">

                    {/* <div className="logo-image"> <a href="https://mchonjb.com/" rel="noreferrer" target="_blank"><img src={logo} alt="Logo" /></a> </div> */}
                    <div className="logo_container" style={{ width: "323px" }} >
                        <div className="logo_n">
                            <div className="logo_layer_back" style={{ width: "100%", height: "100%" }}>
                                <img src={logo1} alt="" />
                            </div>
                            <div className="logo_layer_second">
                                <img src={logo2} alt="" />
                            </div>
                            <div className="logo_layer_top" style={{ width: "100%", height: "100%" }} >
                                <a href="/" rel="noreferrer" target="_blank">
                                    <img src={logo3} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="container myContainer" style={{marginTop: "15vh"}}>
                        <div className="row">
                            <div className="bt-colM col align-self-start">
                                <a href="https://mchonjb-view.fra1.digitaloceanspaces.com/Pure-Flow1.png" rel="noreferrer" target="_blank" style={{ textDecoration: "none", color: "inherit" }}>
                                    Flow
                                </a>
                            </div>
                            <div className="bt-colM col align-self-center">
                                <a href="https://mchonjb-view.fra1.digitaloceanspaces.com/scheme2.png" rel="noreferrer" target="_blank" style={{ textDecoration: "none", color: "inherit" }}>
                                    Experience architect/visual schema
                                </a>
                            </div>
                            <div className="bt-colM col align-self-end">
                                <a href="https://invis.io/CK11V1NYD9WQ" rel="noreferrer" target="_blank" style={{ textDecoration: "none", color: "inherit" }}>
                                    Prototype (InVision)
                                </a>
                            </div>
                        </div>
                    </div>     
                </div>
            </Layout>
        );
    }
}

PageTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array
};

export default PageTemplate;

// STEP #4: Get current WP Post data via ID.
export const pageQuery = graphql`
    query($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            content
            featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      original {
                        src
                        height
                        width
                      }
                    }
                  }
                }
              }
        }
    }
`;